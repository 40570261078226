import Helpers from '../lib/helpers';

class CollapsibleProductSection {
  constructor({ selector }) {
    // Check for existence of selector
    this.originalSelector = selector;
    this.selector = document.querySelectorAll(selector);
    if (!this.selector) { return null; }

    // Run if selector found on page
    CollapsibleProductSection.togglecollapsingButtonWrappers(this.selector);

    // Bind 'this' to the Mutation Observer callback function
    // So we have a reference to the original class inside the callback
    this.MutationObserverCallback = this.MutationObserverCallback.bind(this);

    this.addMutationObservers();
  }

  addMutationObservers() {
    const shortlistButtons = this.selector;

    Helpers.forEach(shortlistButtons, (el, i) => {
      if (el.closest('.product__listing')) {
        // console.log('shortlistButton is inside a product listing');
        const productListingWrapper = el.closest('.product__listing');
        this.productListingWrapper = productListingWrapper;
        const elementToObserve = productListingWrapper;

        this.observer = new MutationObserver(this.MutationObserverCallback);

        this.observer.observe(elementToObserve, {
          childList: true,
          subtree: false,
        });
      }
    });
    this.addCollapsibleButtonEvents();
  }

  MutationObserverCallback(mutationList, observer) {
    // console.log('form has been re-loaded');
    // console.log('MutationObserverCallback() this = ', this);
    // console.log('mutationList in Callback = ', mutationList);
    // console.log('observer in Callback = ', observer);

    let mutatedTarget;
    // if we can log what the elementToObserve was here
    // then we can pass the correct element to add Collapsible Buttons events back onto
    Helpers.forEach(mutationList, (mutation, i) => {
      // console.log('mutation.target = ', mutation.target);
      mutatedTarget = mutation.target;
    });

    // Otherwise we'll have to just apply any changes to all buttons
    this.addCollapsibleButtonEvents(mutatedTarget);
  }

  addCollapsibleButtonEvents(mutatedTarget) {
    // console.log('addCollapsibleButtonEvents() mutatedTarget = ', mutatedTarget);

    // The submitted form element has been replaced, so we need to create a new nodelist
    // with reference to the new Collapsible Button rather than the old one
    const newCollapsibleButtons = document.querySelectorAll(this.originalSelector);

    // Only target a single reloaded form collapsible button if mutatedTarget is defined
    // otherwise get 2 event listeners attached to ones that havent been submitted
    if (mutatedTarget) {
      // Add events to just the new collapsible button
      setTimeout(() => {
        const collapsingButtonWrapper = mutatedTarget.querySelector(this.originalSelector);
        const reloadedCollapsingButton = collapsingButtonWrapper.querySelector('.btn--collapsible-product');
        const collapsingSection = collapsingButtonWrapper.nextElementSibling;

        collapsingSection.hidden = false;

        // set to open
        reloadedCollapsingButton.setAttribute('aria-expanded', 'true');
        collapsingButtonWrapper.setAttribute('aria-expanded', 'true');
        collapsingButtonWrapper.setAttribute('data-state', 'open');

        reloadedCollapsingButton.addEventListener('click', () => {
          // console.log('Collapsible Button clicked');
          reloadedCollapsingButton.setAttribute('aria-expanded', reloadedCollapsingButton.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');

          // Log whether expanded true or false to use for collapsing section visibilty later
          const expanded = collapsingButtonWrapper.getAttribute('aria-expanded') === 'true' || false;

          // Toggle aria-expanded on collapsingButtonWrapper
          collapsingButtonWrapper.setAttribute('data-state', collapsingButtonWrapper.getAttribute('data-state') === 'open' ? 'closed' : 'open');
          collapsingButtonWrapper.setAttribute('aria-expanded', collapsingButtonWrapper.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');

          // Toggle visibility of the collapsing section
          collapsingSection.hidden = expanded;
        });
      }, 100);
    } else {
      // Add to all collapsible section buttons
      Helpers.forEach(newCollapsibleButtons, (el) => {
        // Set to closed originally
        el.setAttribute('aria-expanded', 'false');

        el.addEventListener('click', () => {
          // console.log('Collapsible Button clicked');
          el.setAttribute('aria-expanded', el.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
        });
      });
    }
  }

  static togglecollapsingButtonWrappers(selector) {
    const collapsibleProductButtons = selector;

    Helpers.forEach(collapsibleProductButtons, (collapsibleProductButton) => {
      const btn = collapsibleProductButton.querySelector('.btn--collapsible-product');
      const target = collapsibleProductButton.nextElementSibling;

      target.hidden = 'true';

      btn.onclick = (e) => {
        e.preventDefault();
        // Toggle data-state on collapsibleProductButton container div
        collapsibleProductButton.setAttribute('data-state', collapsibleProductButton.getAttribute('data-state') === 'open' ? 'closed' : 'open');

        // Toggle aria-expanded on collapsibleProductButton
        const expanded = btn.getAttribute('aria-expanded') === 'true' || false;
        btn.setAttribute('aria-expanded', !expanded);
        target.hidden = expanded;
      };
    });
  }
}

export default CollapsibleProductSection;
