class CookieDisclaimer {
  constructor() {
    this.config = {
      classForBody: 'has-cookie-banner',
      name: 'cookies_accepted',
      templateHtml: '<section class="wrapper__content-small"><p id="message"></p><button class="btn" id="close">Close</button></section>',
      message: 'Our website uses cookies to monitor traffic on our website and ensure that we can provide our customers with the best online experience possible. Please read our <a href="/cookies">cookie policy</a> to view more details on the cookies we use.',
    };

    this.run();
  }

  /* eslint no-unused-expressions: "off" */
  run() {
    CookieDisclaimer.readCookie(this.config.name) || (() => {
      const div = document.createElement('div');
      div.setAttribute('class', 'cookie-banner');
      div.setAttribute('id', 'cookie-banner');
      div.innerHTML += this.config.templateHtml;
      document.body.insertBefore(div, document.body.firstChild);
      document.getElementById('message').innerHTML = this.config.message;
      if (document.body.classList) {
        document.body.classList.add(this.config.classForBody);
      } else {
        document.body.className += ` ${this.config.classForBody}`;
      }
      document.getElementById('close').onclick = () => {
        CookieDisclaimer.writeCookie(this.config.name, !0, 1800);
        const a = document.getElementById('cookie-banner');
        a.parentNode.removeChild(a);
        if (document.body.classList) {
          document.body.classList.remove(this.config.classForBody);
        } else {
          document.body.className = document.body.className.replace(new RegExp(`(^|\\b)${this.config.classForBody.split(' ').join('|')}(\\b|$)`, 'gi'), ' ');
        }
        return false;
      };
    })();
  }

  static writeCookie(name, value, days) {
    let expires = '';

    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + 24 * days * 60 * 60 * 1e3);
      expires = `; expires=${date.toGMTString()}`;
    }

    document.cookie = `${name}=${value}${expires}; path=/`;
  }

  static readCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];

      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }

      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }

    return null;
  }
}

export default CookieDisclaimer;
