/* eslint no-new: "off" */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from 'rails-ujs';
import modernizr from 'modernizr';

// IE11 polyfills - as they don't work when within ie11 pack
// Perhaps these polyfills could be put in IE11's global scope where the ie11 pack is called?  Instead of being here.
import '../../javascripts/lib/ie11-polyfills/element-qsa-scope';
import '../../javascripts/lib/ie11-polyfills/nodelist-foreach';
import '../../javascripts/lib/ie11-polyfills/closest';
import '../../javascripts/lib/ie11-polyfills/es6-promise-auto';
import '../../javascripts/lib/ie11-polyfills/find-index';
import '../../javascripts/lib/ie11-polyfills/find';
import '../../javascripts/lib/ie11-polyfills/string-replaceall';

import Helpers from '../../javascripts/lib/helpers';
import CookieDisclaimer from '../../javascripts/lib/cookie-disclaimer';

import AddUserAgentClasses from '../../javascripts/application/add-user-agent-classes';
import Header from '../../javascripts/application/header';
import Search from '../../javascripts/application/search';
import StickyNav from '../../javascripts/application/sticky-nav';
import HeroSlider from '../../javascripts/application/hero-slider';
import GallerySlider from '../../javascripts/application/gallery-slider';
import ShareBar from '../../javascripts/application/share-bar';
import EqualHeightCells from '../../javascripts/application/equal-height-cells';
import Modals from '../../javascripts/application/modals';
import TableFade from '../../javascripts/application/table-fade';
import FakeUploadInput from '../../javascripts/application/fake-upload-input';
import FocusOutlines from '../../javascripts/application/focus-outlines';
import InstagramWidget from '../../javascripts/application/instagram-widget';
// import PointsTooltips from '../../javascripts/application/points-tooltips';
import CollapsibleProductSection from '../../javascripts/application/collapsible-product-section';

import '../../stylesheets/application/application.scss';

import lazySizes from 'lazysizes';
if (!('object-fit' in document.createElement('a').style)) {
  require('lazysizes/plugins/object-fit/ls.object-fit');
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('../../images/application', true);
const imagePath = (name) => images(name, true);

const companyName = 'The School Of Artisan Food';

Rails.start();

Helpers.ready(() => {
  new AddUserAgentClasses();
  new Header({
    headerSelector: 'header',
    menuToggleSelector: '[data-js="menu-toggle"]',
    slidingNav: document.body.classList.contains('has-sliding-nav'),
    slidingNavOverlayClasses: ['overlay', 'nav__overlay', 'is-visible'],
    navigationRootListSelector: 'nav[data-js="primary-navigation"] ul',
    childNavToggleSelector: 'button.subnav-toggle-button',
    tabTrappableItemsWrapperSelector: '.page-header__lower-wrapper',
    tabTrappableItemsSelectors: 'a[href]:not(.page-header__logo), button:not([disabled]), [tabindex="0"]',
    translationsSelector: 'nav[data-js="primary-navigation"]',
    translationAttributeForAriaLabelShow: 'data-translation-aria-label-show',
    translationAttributeForAriaLabelHide: 'data-translation-aria-label-hide',
  });
  new Search({
    searchContainerSelector: '[data-search-container]',
    searchButtonsSelector: '[data-search]',
    searchBarSelector: '[data-search-box]',
  });
  new StickyNav({
    header: '.page-header',
    primaryNav: '.nav__primary',
    main: 'main.page-content',
    body: 'body',
  });
  new HeroSlider({
    selector: '[data-js="hero-slides"]',
  });
  new GallerySlider({
    selector: '[data-js="gallery-slides"]',
  });
  new ShareBar({
    selector: '[data-share-url]',
    companyName: companyName
  });
  new EqualHeightCells({
    selector: '[data-grid-two-thirds].both-images',
    breakpoint: 768,
  });
  new Modals();
  new TableFade({
    selector: 'data-js-table-scroll-fade',
  });
  new CookieDisclaimer();
  new FakeUploadInput();
  new FocusOutlines();
  new InstagramWidget({
    selector: '[data-instagram-widget]',
  });
  // new PointsTooltips({
  //   selector: '[data-points-tooltip]',
  // });
  new CollapsibleProductSection({
    selector: '[data-product-collapsible]',
  });
});
