import Helpers from '../lib/helpers';
import Navigation from './header/navigation';
import MenuButton from './header/menu-button';
import Console from './header/console';

/* eslint max-len: ["error", { "code": 130 }] */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["removeSlidingNavOverlay", "trapTabKey"] }] */

class Header {
  constructor({
    headerSelector,
    menuToggleSelector,
    slidingNav,
    slidingNavOverlayClasses = [],
    breakpoint = 30000,
    navigationRootListSelector,
    childNavToggleSelector,
    tabTrappableItemsWrapperSelector,
    tabTrappableItemsSelectors,
    translationsSelector,
    translationAttributeForAriaLabelShow,
    translationAttributeForAriaLabelHide,
  }) {
    // get and use headerElement to reduce the querySelector scope and save work
    const headerElement = document.querySelector(headerSelector);
    if (!headerElement) { Console.error('header element not found'); return null; }

    this.headerElement = headerElement;
    // Note - there are two .nav_utility nodes in the DOM - this one is the
    // second one - within _header_navigation:
    this.menuToggle = headerElement.querySelector(menuToggleSelector); // burger
    this.slidingNav = slidingNav;
    this.slidingNavOverlayClasses = slidingNavOverlayClasses;
    this.breakpoint = breakpoint;
    this.navigationRootList = headerElement.querySelector(navigationRootListSelector);
    this.tabTrappableItemsWrapper = headerElement.querySelector(tabTrappableItemsWrapperSelector);
    this.tabTrappableItemsSelectors = tabTrappableItemsSelectors;

    if (!this.menuToggle) { Console.error('menuToggle not supplied'); return null; }
    if (typeof this.slidingNav !== 'boolean') { Console.error('slidingNav incorrect'); return null; }
    if (!this.navigationRootList) { Console.error('navigationRootList not supplied'); return null; }
    if (!this.breakpoint) { Console.error('breakpoint not supplied'); return null; }
    if (!this.tabTrappableItemsWrapper) { Console.error('tabTrappableItemsWrapper not found'); return null; }
    if (!this.tabTrappableItemsSelectors) { Console.error('tabTrappableItemsSelectors not supplied'); return null; }

    this.menuButton = new MenuButton({
      headerObject: this,
      menuToggle: this.menuToggle,
      slidingNav: this.slidingNav,
    });

    this.childNavToggleSelector = childNavToggleSelector;
    if (!this.childNavToggleSelector) { Console.error('childNavToggleSelector not supplied'); return null; }
    // Get translations from the DOM so they are consistent with those used by
    // server when rendering the initial HTML.
    const translationsElement = headerElement.querySelector(translationsSelector);
    if (!translationsElement) { Console.error('translationsElement not supplied'); return null; }
    this.translationForAriaLabelShow = translationsElement
      .getAttribute(translationAttributeForAriaLabelShow);
    this.translationForAriaLabelHide = translationsElement
      .getAttribute(translationAttributeForAriaLabelHide);
    if (!this.translationForAriaLabelShow) {
      Console.error('translationForAriaLabelShow not supplied or found'); return null;
    }
    if (!this.translationForAriaLabelHide) {
      Console.error('translationForAriaLabelHide not supplied or found'); return null;
    }

    this.navigation = new Navigation({
      headerObject: this,
      rootList: this.navigationRootList,
      childNavToggleSelector: this.childNavToggleSelector,
      translationForAriaLabelShow: this.translationForAriaLabelShow,
      translationForAriaLabelHide: this.translationForAriaLabelHide,
    });

    // --- Properties which maintain/manage 'state':
    this.menuVisibleOnMobile = false; // set initial state to be closed on mobile
    this.viewportSituation = ''; // e.g. desktop/mobile.  Gets set in viewportWidthChecker (initially, and upon resizes).
    // ---

    this.monitorViewportWidthAndAlterDisplayWhenRequired();
  }

  monitorViewportWidthAndAlterDisplayWhenRequired() {
    this.viewportWidthChecker();

    const resizeDebouncer = Helpers.debounce(() => {
      this.viewportWidthChecker();
    }, 50);
    window.addEventListener('resize', resizeDebouncer, { passive: true });
  }

  viewportSituationDesktop() {
    return (this.viewportSituation === 'desktop');
  }

  viewportSituationMobile() {
    return (this.viewportSituation === 'mobile');
  }

  // This is run initially, or on resize - so these methods are purely related
  // to viewport width, NOT click/etc interactions
  viewportWidthChecker() {
    Console.log('viewportWidthChecker', this.viewportSituation);

    const { clientWidth } = document.documentElement;
    const viewportWidth = Math.max(clientWidth, window.innerWidth || 0); // px value

    if (viewportWidth >= this.breakpoint) {
      if (!this.viewportSituationDesktop()) {
        // Should be in viewportSituation of desktop - move to that now
        this.viewportSituation = 'desktop';
        this.showTabbableElements('desktop'); // peculiar instance hence argument
        if (this.slidingNav) {
          this.removeSlidingNavOverlay();
        }
      }
    } else if (!this.viewportSituationMobile()) {
      // Should be in viewportSituation of mobile - move to that now
      this.viewportSituation = 'mobile';
      // this.hideMenuOnMobile(); // this is the existing behaviour, but I'd query whether we really want to do this
      if (this.menuVisibleOnMobile) {
        this.showTabbableElements();
        if (this.slidingNav) {
          this.addSlidingNavOverlay();
        }
      } else {
        this.hideTabbableElements();
      }
    }
  }

  addSlidingNavOverlay() {
    Console.log('addSlidingNavOverlay');
    // create overlay to mask the other part of the screen while menu is open
    const overlay = document.createElement('div');
    // have to add classes individually for IE11 to cope:
    this.slidingNavOverlayClasses.forEach((slidingNavOverlayClass) => {
      overlay.classList.add(slidingNavOverlayClass);
    });
    overlay.setAttribute('data-js', 'sliding-nav-overlay');
    document.body.appendChild(overlay);
    // add event listener to the overlay
    overlay.addEventListener(
      'click',
      (e) => this.menuButton.hideMenuOnMobile(e),
      { passive: true },
    );
  }

  removeSlidingNavOverlay() {
    Console.log('removeSlidingNavOverlay');
    // remove overlay (if one was present from mobile nav being open)
    const overlay = document.querySelector('[data-js="sliding-nav-overlay"]');
    if (overlay) { overlay.parentNode.removeChild(overlay); }
  }

  showTabbableElements() {
    // show elements visually, and so they may now be tabbed to
    Console.log('showTabbableElements');

    // Add class to hide focusable sliding nav elements
    this.headerElement.classList.remove('hide-nav-children');
  }

  hideTabbableElements() {
    // hide elements so they may not be tabbed to
    Console.log('hideTabbableElements');

    // Remove class that hides focusable sliding nav elements
    this.headerElement.classList.add('hide-nav-children');
  }

  static get firstTabTrapAttribute() { return 'data-tab-trap-first-element'; }

  static get lastTabTrapAttribute() { return 'data-tab-trap-last-element'; }

  trapTabbing() {
    // Don't let the user tab beyond the menu.  For use when viewing mobile version and the menu is open.

    // Add attributes as markers to the first and last focusable elements, if they've not already been added previously.
    const tabTrapMarkerSelectors = `[${Header.firstTabTrapAttribute}], [${Header.lastTabTrapAttribute}]`;
    if (this.tabTrappableItemsWrapper.querySelectorAll(tabTrapMarkerSelectors).length !== 2) {
      Console.log('Adding first and last marker attributes for tab trapping');
      let focusableElements = this
        .tabTrappableItemsWrapper
        .querySelectorAll(this.tabTrappableItemsSelectors); /* eslint prefer-const: "off" */
      focusableElements[0].setAttribute(Header.firstTabTrapAttribute, '');
      focusableElements[focusableElements.length - 1].setAttribute(Header.lastTabTrapAttribute, '');
    }

    Console.log('Adding tab trapping event listener');
    document.addEventListener(
      'keydown',
      this.trapTabKey,
    );
  }

  trapTabKey(e) {
    if (e.keyCode === 9) { // TAB KEY
      if (e.shiftKey) { // SHIFT + TAB
        if (document.activeElement.hasAttribute(Header.firstTabTrapAttribute)) {
          Console.log('Tab trapping first element:', document.activeElement);
          e.preventDefault();
          const lastElement = document.querySelector(`[${Header.lastTabTrapAttribute}]`);
          Console.log('lastElement is:', lastElement);
          if (lastElement) {
            lastElement.focus();
          }
        }
      } else if (document.activeElement.hasAttribute(Header.lastTabTrapAttribute)) {
        Console.log('Tab trapping last element:', document.activeElement);
        e.preventDefault();
        const firstElement = document.querySelector(`[${Header.firstTabTrapAttribute}]`);
        Console.log('lastElement is:', firstElement);
        if (firstElement) {
          firstElement.focus();
        }
      }
    }
  }

  unTrapTabbing() {
    Console.log('Removing tab trapping event listener');
    document.removeEventListener(
      'keydown',
      this.trapTabKey,
    );
  }
}

export default Header;
