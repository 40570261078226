import Helpers from '../lib/helpers';

class Search {
  constructor({ searchContainerSelector, searchButtonsSelector, searchBarSelector }) {
    this.searchContainerSelector = searchContainerSelector;
    this.searchButtonsSelector = searchButtonsSelector;
    this.searchBarSelector = searchBarSelector;

    if (!this.searchContainerSelector) { return null; }
    if (!this.searchButtonsSelector) { return null; }
    if (!this.searchBarSelector) { return null; }

    // Show / hide the search bar
    let searchContainer = document.querySelectorAll(this.searchContainerSelector);
    let searchButtons = document.querySelectorAll(this.searchButtonsSelector);
    let searchBar = document.querySelector(this.searchBarSelector);

    if (!searchContainer) { return null; }
    if (!searchButtons) { return null; }

    Helpers.forEach(searchContainer, (el, i) => {
      var searchBtn = el.querySelector('[data-search]');
      var searchBar = el.querySelector('[data-search-box]');
      var searchInput = el.querySelector('input[type="search"]');

      searchBtn.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (searchBar) {
          if (searchBar.getAttribute('data-visible') != null) {
            searchBar.removeAttribute('data-visible');
            el.setAttribute('aria-expanded', 'false');
            searchBtn.removeAttribute('data-visible');
          } else {
            searchBar.setAttribute('data-visible', '');
            searchBtn.setAttribute('data-visible', '');
            el.setAttribute('aria-expanded', 'true');
            searchInput.focus();
          }
        }
      });
    });
  }
}

export default Search;
