class InstagramWidget {
  constructor({ selector }) {
    this.selector = document.querySelector(selector);
    if (!this.selector) { return null; }

    this.llt = this.selector.dataset.instagramWidget;
    this.queryUserMedia();
  }

  queryUserMedia() {
    fetch(this.formMediaEdgeUrl()).then((response) => {
      // The API call was successful!
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    }).then((data) => {
      this.userMediaData = data;
      // console.log('queryUserMedia() this.userMediaData  = ', this.userMediaData);
      this.createWidget();
    }).catch((err) => {
      // There was an error
      console.warn('Something went wrong getting data from instagram.', err);
    });
  }

  // Insert images into the HTML
  createWidget() {
    let i;
    for (i = 0; i < 4; i += 1) {
      let thumbnailUrl = this.userMediaData.data[i].media_url;
      // Video thumbnails are .thumbnail_url not .media_url
      if (this.userMediaData.data[i].thumbnail_url) {
        thumbnailUrl = this.userMediaData.data[i].thumbnail_url;
      }
      this.selector.innerHTML += `<a href="${this.userMediaData.data[i].permalink}" target="_blank" rel="noreferrer noopener" class="social-grid__instagram-link"><img src="${thumbnailUrl}" class="social-grid__instagram-image lazyload"></a>`;
    }
  }

  // Instagram Basic Display API
  // Query the 'User Media Edge' for latest post data
  // https://developers.facebook.com/docs/instagram-basic-display-api/guides/getting-profiles-and-media#step-2--query-the-user-media-edge
  // Fields: https://developers.facebook.com/docs/instagram-basic-display-api/reference/media#fields
  formMediaEdgeUrl() {
    this.basicApiRequestUrl = 'https://graph.instagram.com/me/';
    this.basicApiMediaFields = 'id,caption,media_url,permalink,thumbnail_url';
    this.basicApiAccessToken = this.llt;
    const formedApiUrl = `${this.basicApiRequestUrl}media?fields=${this.basicApiMediaFields}&access_token=${this.basicApiAccessToken}`;
    return formedApiUrl;
  }
}

export default InstagramWidget;
